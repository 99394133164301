<template>
  <div class="secretariat-dashboard">
    <v-card class="text-center pa-10 cards" v-if="!isLoaded">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <circle-4></circle-4>
        <h6 class="mt-6">در حال بارگذاری ...</h6>
      </div>
    </v-card>
    <div v-else>
      <div
        v-if="
          $router.currentRoute.path == '/' + role + '/secretariat/dashboard'
        "
      >
        <div class="header pe-3 ps-3">
          <v-row>
            <v-col>
              <div @click="goTo('inbox')" class="item">
                <div class="icon-box">
                  <v-icon>$Inbox</v-icon>
                </div>
                <div class="text-box">
                  <p class="mb-2">صندوق ورودی</p>
                  <span>{{ dashboard.inboxLettersNotSeenCount }}</span>
                </div>
              </div>
            </v-col>
            <v-col>
              <div @click="goTo('internal')" class="item">
                <div class="icon-box">
                  <v-icon>$Outbox</v-icon>
                </div>
                <div class="text-box">
                  <p class="mb-2">دریافتی‌های سازمانی</p>
                  <span>{{ dashboard.internalLettersNotSeenCount }}</span>
                </div>
              </div>
            </v-col>
            <v-col>
              <div @click="goTo('reminders')" class="item">
                <div class="icon-box">
                  <v-icon>$Letters</v-icon>
                </div>
                <div class="text-box">
                  <p class="mb-2">یادآوری پیگیری‌ها</p>
                  <span>{{ dashboard.pendingFollowingsCount }}</span>
                </div>
              </div>
            </v-col>
            <v-col>
              <div @click="goTo('assignedLetters')" class="item">
                <div class="icon-box">
                  <v-icon>$Archive</v-icon>
                </div>
                <div class="text-box">
                  <p class="mb-2">تخصیص به من</p>
                  <span>{{ dashboard.assigneLettersCount }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="main-container mt-6">
          <v-row>
            <v-col lg="6" md="12" cols="12">
              <v-card class="pb-1 cards" v-if="dashboard.canSign">
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex align-items-center">
                    <v-icon>$EditLetter</v-icon>
                    <span @click="goTo('waitingList')" class="ps-4 pe-4 pointer"
                      >در انتظار امضا</span
                    >
                    <div
                      class="d-flex flex-row"
                      v-if="dashboard.waitingForSignLetters.length"
                    >
                      <v-divider color="#ccc" vertical></v-divider>
                      <span class="ps-4 pe-4" style="padding-top: 6px">
                        {{ dashboard.waitingForSignLettersCount }} نامه</span
                      >
                    </div>
                  </div>

                  <div
                    v-if="dashboard.waitingForSignLetters.length"
                    class="see-more d-flex align-items-center pointer"
                  >
                    <span @click="goToWaitingList()"
                      >مشاهده بیشتر
                      <v-icon class="pt-1 ps-1">mdi-chevron-left</v-icon></span
                    >
                  </div>
                </div>
                <div
                  v-if="dashboard.waitingForSignLetters.length"
                  class="custom-table mt-5"
                >
                  <b-table
                    responsive
                    show-empty
                    stacked="sm"
                    :items="dashboard.waitingForSignLetters"
                    :fields="fields"
                    empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    striped
                    thead-class="headClass"
                    tbody-class="bodyClass"
                    :busy="isBusy"
                    @row-dblclicked="onRowSelected"
                  >
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(sender)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.value.length > 15
                            ? data.value.slice(0, 15) + "..."
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{
                                data.value.length > 20
                                  ? data.value.slice(0, 20) + "..."
                                  : data.value
                              }}
                            </span>
                          </template>
                          <span>{{ data.value }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <v-progress-circular indeterminate color="#00acc1">
                      </v-progress-circular>
                    </div>
                  </b-table>
                </div>
                <div
                  v-else
                  class="border-box text-center mt-6 mb-3 d-flex flex-column justify-content-center"
                >
                  <v-icon style="margin-left: 35px">$NoLetter2</v-icon>
                  <span class="mt-10">نامه‌ای در انتظار امضا وجود ندارد</span>
                </div>
              </v-card>
              <v-card class="pb-1 cards" v-else>
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex align-items-center">
                    <v-icon class="blue-icon">$Inbox</v-icon>
                    <span @click="goTo('internal')" class="ps-4 pe-4 pointer"
                      >آخرین نامه‌های دریافتی سازمانی</span
                    >
                    <div
                      class="d-flex flex-row"
                      v-if="dashboard.internalInboxLetters.length"
                    >
                      <v-divider color="#ccc" vertical></v-divider>
                      <span class="ps-4 pe-4" style="padding-top: 6px"
                        >{{ dashboard.internalInboxLettersCount }} نامه</span
                      >
                    </div>
                  </div>

                  <div
                    v-if="dashboard.internalInboxLetters.length"
                    class="see-more d-flex align-items-center pointer"
                  >
                    <span @click="goTo('internal')"
                      >مشاهده بیشتر
                      <v-icon class="pt-1 ps-1">mdi-chevron-left</v-icon></span
                    >
                  </div>
                </div>
                <div
                  v-if="dashboard.internalInboxLetters.length"
                  class="custom-table mt-5"
                >
                  <b-table
                    responsive
                    show-empty
                    stacked="sm"
                    :items="dashboard.internalInboxLetters"
                    :fields="fields"
                    empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    striped
                    thead-class="headClass"
                    tbody-class="bodyClass"
                    :busy="isBusy"
                    @row-dblclicked="onRowSelected"
                  >
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(sender)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.value.length > 15
                            ? data.value.slice(0, 15) + "..."
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{
                                data.value.length > 20
                                  ? data.value.slice(0, 20) + "..."
                                  : data.value
                              }}
                            </span>
                          </template>
                          <span>{{ data.value }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <v-progress-circular indeterminate color="#00acc1">
                      </v-progress-circular>
                    </div>
                  </b-table>
                </div>
                <div
                  v-else
                  class="border-box text-center mt-6 mb-3 d-flex flex-column justify-content-center"
                >
                  <v-icon style="margin-left: 35px">$NoLetter2</v-icon>
                  <span class="mt-10">نامه‌ دریافتی وجود ندارد</span>
                </div>
              </v-card>
              <v-card class="mt-6 pb-1 cards">
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex align-items-center">
                    <v-icon>$Clock</v-icon>
                    <span @click="goTo('reminders')" class="ps-4 pe-4 pointer"
                      >یادآوری پیگیری</span
                    >
                    <div
                      class="d-flex flex-row"
                      v-if="dashboard.pendingFollowings.length"
                    >
                      <v-divider color="#ccc" vertical></v-divider>
                      <span class="ps-4 pe-4" style="padding-top: 6px"
                        >{{ dashboard.pendingFollowingsCount }} نامه</span
                      >
                    </div>
                  </div>
                  <div
                    v-if="dashboard.pendingFollowings.length"
                    class="see-more d-flex align-items-center pointer"
                  >
                    <span @click="goToFollowingList()"
                      >مشاهده بیشتر
                      <v-icon class="pt-1 ps-1">mdi-chevron-left</v-icon></span
                    >
                  </div>
                </div>
                <div
                  v-if="dashboard.pendingFollowings.length"
                  class="custom-table mt-5"
                >
                  <b-table
                    responsive
                    show-empty
                    stacked="sm"
                    :items="dashboard.pendingFollowings"
                    :fields="followingFields"
                    empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                    empty-text="رکوردی برای نمایش وجود ندارد"
                    striped
                    thead-class="headClass"
                    tbody-class="bodyClass"
                    :busy="isBusy"
                    @row-dblclicked="onRowSelected"
                  >
                    <template v-slot:cell(index)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.index + 1 }}
                      </div>
                    </template>
                    <template v-slot:head()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.label }}
                      </div>
                    </template>
                    <template v-slot:cell()="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{ data.value }}
                      </div>
                    </template>
                    <template v-slot:cell(sender)="data">
                      <div style="text-align: center; vertical-align: middle">
                        {{
                          data.value.length > 15
                            ? data.value.slice(0, 15) + "..."
                            : data.value
                        }}
                      </div>
                    </template>
                    <template v-slot:cell(title)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{
                                data.value.length > 20
                                  ? data.value.slice(0, 20) + "..."
                                  : data.value
                              }}
                            </span>
                          </template>
                          <span>{{ data.value }}</span>
                        </v-tooltip>
                      </div>
                    </template>

                    <template v-slot:cell(operation)="data">
                      <div style="text-align: center; vertical-align: middle">
                        <!-- <b-button variant="success" @click="downloadFile(data.download)"><i
                        class="fa fa-download"
                        aria-hidden="true"
                    ></i></b-button>-->
                        <a
                          class="btn btn-success"
                          :href="data.item.url"
                          target="_blank"
                          role="button"
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                        </a>
                        &nbsp;
                        <b-btn
                          v-if="role == 'Manager' || role == 'secretary'"
                          variant="danger"
                          @click="delFile(data.item)"
                        >
                          <i class="fa fa-remove" aria-hidden="true"></i>
                        </b-btn>
                      </div>
                    </template>
                    <div slot="table-busy" class="text-center text-danger my-2">
                      <v-progress-circular indeterminate color="#00acc1">
                      </v-progress-circular>
                    </div>
                  </b-table>
                </div>
                <div
                  v-else
                  class="border-box text-center mt-6 mb-3 d-flex flex-column justify-content-center"
                >
                  <v-icon style="margin-left: 35px">$NoClock</v-icon>
                  <span class="mt-10"
                    >یادآوری پیگیری برای نمایش وجود ندارد</span
                  >
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="cards">
                <p style="font-size: 17px">ثبت پیش‌نویس سریع</p>
                <div class="mt-8">
                  <v-text-field
                    outlined
                    dense
                    v-model="letterDetails.title"
                    label="موضوع نامه"
                    class="mt-8"
                  >
                  </v-text-field>
                  <v-select
                    label="انتخاب نوع نامه"
                    :items="options"
                    v-model="letterDetails.type"
                    outlined
                    dense
                    @change="
                      letterDetails.sender = '';
                      letterDetails.receiver = '';
                      selectedSender = '';
                      selectedReceiver = '';
                      selectedReceivers = [];
                      selectedReceiverIds = [];
                    "
                  >
                  </v-select>
                  <v-row>
                    <v-col v-if="!letterDetails.sender">
                      <v-btn
                        class="w-100 blue-mainBtn"
                        height="40"
                        @click="openSelectUserDialog('sender')"
                        ><v-icon class="white-icon ps-2 pe-2"
                          >$AddPerson</v-icon
                        >
                        انتخاب فرستنده</v-btn
                      >
                    </v-col>
                    <v-col v-else class="mt-1 d-flex justify-space-between">
                      <div class="mt-2">
                        <span class="grey-color">فرستنده: </span>
                        <span
                          class="h6 font-weight-bold dark-grey-color"
                          v-if="letterDetails.type == 'inbox'"
                        >
                          {{ letterDetails.sender.choiceName }}
                        </span>
                        <span
                          v-else
                          class="h6 font-weight-bold dark-grey-color"
                        >
                          {{ letterDetails.sender.name }}</span
                        >
                      </div>

                      <v-btn
                        class="float-end blue-borderedBtn ms-1"
                        @click="openSelectUserDialog('sender')"
                      >
                        <v-icon class="pe-1 blue-icon">$AddPerson</v-icon>
                        تغییر</v-btn
                      >
                    </v-col>

                    <v-col
                      v-if="
                        letterDetails.type != 'internal'
                          ? !letterDetails.receiver
                          : !letterDetails.selectedReceivers.length
                      "
                    >
                      <v-btn
                        class="w-100 blue-mainBtn"
                        height="40"
                        @click="openSelectUserDialog('receiver')"
                        ><v-icon class="white-icon ps-2 pe-2"
                          >$AddPerson</v-icon
                        >
                        انتخاب گیرنده</v-btn
                      >
                    </v-col>
                    <v-col v-else class="mt-1 d-flex justify-space-between">
                      <div class="mt-2">
                        <div v-if="letterDetails.type != 'internal'">
                          <span class="grey-color">گیرنده: </span>
                          <span
                            v-if="letterDetails.type == 'outbox'"
                            class="h6 font-weight-bold dark-grey-color"
                            >{{ letterDetails.receiver.choiceName }}</span
                          >
                          <span
                            v-else
                            class="h6 font-weight-bold dark-grey-color"
                            >{{ letterDetails.receiver.name }}</span
                          >
                        </div>
                        <div v-else>
                          <span class="grey-color">گیرنده:</span>

                          <b v-if="letterDetails.selectedReceivers.length == 1">
                            {{ letterDetails.selectedReceivers[0].name }}
                          </b>

                          <b v-else class="ps-1">
                            <v-tooltip max-width="300" top>
                              <template v-slot:activator="{ on, attrs }">
                                <b v-bind="attrs" v-on="on">
                                  {{ letterDetails.selectedReceivers[0].name }}
                                  و
                                  {{
                                    letterDetails.selectedReceivers.length - 1
                                  }}
                                  نفر دیگر
                                </b>
                              </template>
                              <span class="fs-xsmall">
                                {{
                                  letterDetails.selectedReceivers
                                    .map((x) => x.name)
                                    .join(" , ")
                                }}
                              </span>
                            </v-tooltip>
                          </b>
                        </div>
                      </div>

                      <v-btn
                        class="float-end blue-borderedBtn ms-2"
                        @click="openSelectUserDialog('receiver')"
                      >
                        <v-icon class="pe-1 blue-icon">$AddPerson</v-icon>
                        تغییر</v-btn
                      >
                    </v-col>
                  </v-row>
                  <!--<v-row>
                  <v-col>
                    <v-btn
                      v-if="!letter.sender"
                      @click="openDialog('sender')"
                      class="blue-borderedBtn w-100"
                      ><v-icon class="ps-2 pe-2">$AddPerson</v-icon>انتخاب فرستنده
                    </v-btn>
                    <div
                      v-else
                      class="pt-2 d-flex flex-row justify-space-between"
                    >
                      <span style="font-size: 16px"
                        >فرستنده: {{ letter.sender }}</span
                      >
                      <span @click="editUser('sender')" class="pointer">
                        <v-icon class="user-edit ps-3 pe-3">$PenSquare</v-icon>
                      </span>
                    </div>
                  </v-col>
                  <v-col>
                    <v-btn
                      v-if="!letter.receiver"
                      @click="openDialog('receiver')"
                      class="blue-borderedBtn w-100"
                      ><v-icon class="ps-2 pe-2">$AddPerson</v-icon>انتخاب گیرنده
                    </v-btn>
                    <div
                      class="pt-2 d-flex flex-row justify-space-between"
                      v-else
                    >
                      <span style="font-size: 16px"
                        >گیرنده: {{ letter.receiver }}</span
                      >
                      <span @click="editUser('receiver')" class="pointer">
                        <v-icon class="user-edit ps-3 pe-3">$PenSquare</v-icon>
                      </span>
                    </div>
                  </v-col>
                </v-row>-->

                  <div class="grey-ckeditor mt-6">
                    <vue-ckeditor
                      v-if="
                        letterDetails.type == 'internal' ||
                        letterDetails.type == 'outbox'
                      "
                      v-model="letterDetails.text"
                      :config="config"
                    />
                    <vue-ckeditor
                      v-else
                      v-model="letterDetails.abstract"
                      :config="config"
                    />
                  </div>
                  <v-row class="mt-2">
                    <v-col>
                      <div class="tag-box">
                        <h5 class="mt-1 ms-1 grey-color">افزودن تگ</h5>
                        <div class="blue-input">
                          <div class="d-flex flex-row">
                            <v-text-field
                              outlined
                              dense
                              height="40"
                              class="my-1"
                              hide-details
                              v-model="tagName"
                              @keyup.enter="addNewTag()"
                            >
                              <template slot="label">
                                <div style="font-size: 14px">عنوان تگ</div>
                              </template>
                            </v-text-field>
                            <v-btn
                              class="ms-2 mt-1 blue-mainBtn"
                              width="60"
                              min-width="40"
                              height="40"
                              @click="addNewTag()"
                              >ثبت</v-btn
                            >
                          </div>
                        </div>
                        <div v-if="letterDetails.tags" class="mt-2">
                          <v-row v-if="letterDetails.tags.length">
                            <v-col class="d-flex flex-wrap pb-0">
                              <div
                                class="blue-badge me-2 mb-2"
                                v-for="(item, index) in letterDetails.tags"
                                :key="index"
                              >
                                <span>{{ item }}</span>
                                <span
                                  ><v-icon
                                    @click="removeTag(index)"
                                    color="#515BA1"
                                    small
                                    class="ps-1"
                                    >mdi-close</v-icon
                                  ></span
                                >
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-btn
                    class="blue-mainBtn mt-9"
                    height="43"
                    @click="sendLetter()"
                    :loading="sending"
                    :disabled="
                      !letterDetails.sender ||
                      (letterDetails.type == 'internal'
                        ? !letterDetails.selectedReceivers.length
                        : !letterDetails.receiver) ||
                      !letterDetails.title ||
                      (letterDetails.type == 'inbox'
                        ? !letterDetails.abstract
                        : !letterDetails.text)
                    "
                    >ذخیره پیش‌نویس</v-btn
                  >
                  <v-btn
                    :disabled="
                      !letterDetails.sender ||
                      (letterDetails.type == 'internal'
                        ? !letterDetails.selectedReceivers.length
                        : !letterDetails.receiver) ||
                      !letterDetails.title ||
                      (letterDetails.type == 'inbox'
                        ? !letterDetails.abstract
                        : !letterDetails.text)
                    "
                    class="blue-mainBtn mt-9 float-end"
                    height="43"
                    @click="goToLetterInfo()"
                    >تکمیل و ارسال</v-btn
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-dialog v-model="choosePersonDialog" width="600">
          <v-card class="dash-modal-card" style="overflow-y: auto !important">
            <v-row class="mb-2">
              <v-col class="pt-4">
                <h5 class="mt-1 grey-color">
                  {{
                    userType == "sender" ? "انتخاب فرستنده" : "انتخاب گیرنده"
                  }}
                </h5>
              </v-col>
              <v-col class="text-left">
                <div class="custom-input">
                  <v-text-field
                    class="float-left"
                    v-model="search"
                    outlined
                    dense
                    placeholder="جستجو"
                  >
                    <template #prepend-inner>
                      <v-icon> $Magnify </v-icon>
                    </template>
                    <template v-if="search.length" #append>
                      <v-icon @click="search = ''"> $Close </v-icon>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
            <div
              v-if="
                (computedIsContact && !computedContacts.length && search) ||
                (!computedIsContact && !computedUsers.length && search) ||
                (computedIsSignatories && !computedSignatories.length && search)
              "
              class="text-center h5 pa-3"
            >
              نتیجه‌ای برای "{{ search }}" یافت نشد.
            </div>

            <div v-else class="users ps-3 pe-3">
              <div
                v-if="userType == 'sender' && letterDetails.type == 'outbox'"
              >
                <div v-if="signatoriesLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageSignatories"
                      :per-page="perPageSignatories"
                      :items="computedSignatories"
                      :fields="employeesFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="
                  blue-trClass
                      "
                      :busy="isBusy2"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageSignatories * (currentPageSignatories - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>
                      <template v-slot:cell(name)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="selectedSender != data.item"
                            @click="selectUser(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedSender == data.item"
                            @click="
                              selectedSender = '';
                              letterDetails.sender = '';
                            "
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn
                        @click="goToFirstSignatories()"
                        class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageSignatories"
                        :length="
                          Math.ceil(
                            computedSignatories.length / perPageSignatories
                          )
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn
                        @click="goToLastSignatories()"
                        class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
              <div
                v-if="userType == 'sender' && letterDetails.type == 'internal'"
              >
                <div v-if="employeesLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageEmployee"
                      :per-page="perPageEmployee"
                      :items="computedUsers"
                      :fields="employeesFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="
                  blue-trClass
                      "
                      :busy="isBusy2"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageEmployee * (currentPageEmployee - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>
                      <template v-slot:cell(name)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="selectedSender != data.item"
                            @click="selectUser(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedSender == data.item"
                            @click="
                              selectedSender = '';
                              letterDetails.sender = '';
                            "
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn @click="goToFirstEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageEmployee"
                        :length="
                          Math.ceil(computedUsers.length / perPageEmployee)
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn @click="goToLastEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
              <div v-if="userType == 'sender' && letterDetails.type == 'inbox'">
                <div v-if="contactsLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageContact"
                      :per-page="perPageContact"
                      :items="computedContacts"
                      :fields="contactsFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="blue-trClass
                      "
                      :busy="isBusy1"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageContact * (currentPageContact - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="selectedSender != data.item"
                            @click="selectUser(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedSender == data.item"
                            @click="
                              selectedSender = '';
                              letterDetails.sender = '';
                            "
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn @click="goToFirstContact()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageContact"
                        :length="
                          Math.ceil(computedContacts.length / perPageContact)
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn @click="goToLastContact()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
              <div
                v-if="userType == 'receiver' && letterDetails.type == 'inbox'"
              >
                <div v-if="employeesLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageEmployee"
                      :per-page="perPageEmployee"
                      :items="computedUsers"
                      :fields="employeesFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="blue-trClass"
                      :busy="isBusy2"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageEmployee * (currentPageEmployee - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>
                      <template v-slot:cell(name)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="selectedReceiver != data.item"
                            @click="selectUser(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedReceiver == data.item"
                            @click="
                              selectedReceiver = '';
                              letterDetails.receiver = '';
                            "
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn @click="goToFirstEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageEmployee"
                        :length="
                          Math.ceil(computedUsers.length / perPageEmployee)
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn @click="goToLastEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
              <div
                v-if="
                  userType == 'receiver' && letterDetails.type == 'internal'
                "
              >
                <div v-if="employeesLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageEmployee"
                      :per-page="perPageEmployee"
                      :items="computedUsers"
                      :fields="employeesFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="blue-trClass"
                      :busy="isBusy2"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageEmployee * (currentPageEmployee - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>
                      <template v-slot:cell(name)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="!selectedReceivers.includes(data.item)"
                            @click="selectUsers(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedReceivers.includes(data.item)"
                            @click="removeUser(data.item)"
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn @click="goToFirstEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageEmployee"
                        :length="
                          Math.ceil(computedUsers.length / perPageEmployee)
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn @click="goToLastEmployee()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
              <div
                v-if="userType == 'receiver' && letterDetails.type == 'outbox'"
              >
                <div v-if="contactsLoading" class="text-center">
                  <v-progress-circular indeterminate color="#7e57c2">
                  </v-progress-circular>
                  <h6 color="#7e57c2" class="mt-4">در حال بارگذاری...</h6>
                </div>
                <div v-else>
                  <div class="custom-table">
                    <b-table
                      responsive
                      show-empty
                      stacked="sm"
                      :current-page="currentPageContact"
                      :per-page="perPageContact"
                      :items="computedContacts"
                      :fields="contactsFields"
                      empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      striped
                      thead-class="headClass"
                      tbody-class="blue-trClass
                      "
                      :busy="isBusy1"
                    >
                      <template v-slot:cell(index)="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0"
                        >
                          {{
                            data.index +
                            1 +
                            perPageContact * (currentPageContact - 1)
                          }}
                        </div>
                      </template>
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div
                          style="text-align: center; vertical-align: middle"
                          class="pt-0 grey-color"
                        >
                          <div class="mt-2">{{ data.value }}</div>
                        </div>
                      </template>

                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            v-if="selectedReceiver != data.item"
                            @click="selectUser(data.item)"
                            height="40"
                            width="80"
                            class="blue-mainBtn pa-3"
                            >انتخاب</v-btn
                          >
                          <v-btn
                            v-if="selectedReceiver == data.item"
                            @click="
                              selectedReceiver = '';
                              letterDetails.receiver = '';
                            "
                            height="40"
                            class="red-mainBtn pa-3"
                            width="80"
                            >حذف</v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center text-danger my-2"
                      >
                        <v-progress-circular indeterminate color="#00acc1">
                        </v-progress-circular>
                      </div>
                    </b-table>
                    <div
                      class="d-flex flex-row justify-content-end blue-custom-pagination"
                    >
                      <v-btn @click="goToFirstContact()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                      >
                      <v-pagination
                        v-model="currentPageContact"
                        :length="
                          Math.ceil(computedContacts.length / perPageContact)
                        "
                        :total-visible="5"
                        prev-icon="$ArrowLeft"
                        next-icon="$ArrowRight"
                      ></v-pagination>
                      <v-btn @click="goToLastContact()" class="navigation-btn"
                        ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <!--<div
                v-for="(item, index) in computedContacts"
                :key="index"
                @click="selectUser(item)"
                :class="[selectedSender == item ? 'purple-select' : '']"
                class="py-3 mx-2 ps-2 purple-hover"
              >
                <div class="d-flex flex-row justify-space-between">
                  <div class="d-flex flex-row">
                    <div class="image-box">
                      <v-img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnth5-wQ3awrdVlDxI3cZO1tewLa37xD-MjQ&usqp=CAU"
                      ></v-img>
                    </div>
                    <span style="font-size: 1rem" class="px-5 pt-5">{{
                      item.choiceName
                    }}</span>
                  </div>
                </div>
              </div>-->
                </div>
              </div>
            </div>

            <v-card-actions class="ps-0 pe-0">
              <v-btn
                @click="submitUser()"
                width="130"
                height="40"
                class="mt-6 float-start blue-mainBtn"
                v-if="
                  userType == 'sender'
                    ? selectedSender
                    : selectedReceiver || selectedReceivers.length
                "
                >تایید</v-btn
              >

              <v-spacer></v-spacer>
              <v-btn
                @click="choosePersonDialog = false"
                width="130"
                height="40"
                class="red-borderedBtn mt-6"
                >بازگشت</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import VueCkeditor from "vue-ckeditor2";
import Axios from "axios";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    VueCkeditor,
    Circle4,
  },
  data() {
    return {
      tagName: "",
      isBusy2: false,
      isBusy1: false,
      fields: [
        { key: "letterNo", label: "شماره نامه" },
        { key: "internalSenderName", label: "فرستنده" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
      ],
      followingFields: [
        { key: "letterNo", label: "شماره نامه" },
        // { key: "sender", label: "فرستنده" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "followingDate", label: "تاریخ پیگیری" },
      ],
      options: [
        { value: "internal", text: "سازمانی" },
        //{ value: "inbox", text: "ورودی" },
        { value: "outbox", text: "خروجی" },
      ],
      types: [
        { value: "inbox", text: "inbox" },
        { value: "outbox", text: "outbox" },
        { value: "internal", text: "internal" },
      ],
      config: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "TextColor",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "Font",
            "FontSize",
            "Smiley",
          ],
        ],
        height: 200,
      },
      dashboard: {},
      letterDetails: {
        type: "internal",
        tags: [],
        selectedReceivers: [],
        selectedReceiverIds: [],
      },
      isBusy: false,
      sending: false,
      usersDialog: false,
      search: "",
      type: "",
      selectedUser: "",
      choosePersonDialog: false,
      selectedSender: "",
      selectedReceiver: "",
      selectedReceivers: [],
      selectedReceiverIds: [],
      userType: "",
      contactsLoading: false,
      signatoriesLoading: false,
      employees: [],
      contacts: [],
      isLoaded: false,
      newLetterId: "",
      letterType: "",
      contactsFields: [
        { key: "index", label: "#" },
        { key: "choiceName", label: "نام" },
        { key: "operation", label: "انتخاب" },
      ],
      employeesFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "operation", label: "انتخاب" },
      ],
      employeesLoading: false,
      currentPageContact: 1,
      perPageContact: 4,
      currentPageEmployee: 1,
      perPageEmployee: 4,
      currentPageSignatories: 1,
      perPageSignatories: 4,
      signatories: [],
      role: "",
    };
  },
  methods: {
    goTo(page) {
      this.$router.push("/" + this.role + "/secretariat/" + page);
    },
    addNewTag() {
      if (!this.letterDetails.tags.includes(this.tagName.trim())) {
        this.letterDetails.tags.push(this.tagName.trim());
        this.tagName = "";
      } else {
        this.$toast("این تگ پیش از این افزوده شده است.", "error");
      }
    },
    removeTag(index) {
      this.letterDetails.tags.splice(index, 1);
    },
    onRowSelected(items) {
      this.$router.push(
        "/" +
          this.role +
          "/secretariat/" +
          this.types.filter((x) => x.text == items.type)[0].value +
          "/letterInfo/" +
          items._id
      );
    },
    editUser(type) {
      this.type = type;
      this.selectedUser = this.letter[type];
      this.search = "";
      this.usersDialog = true;
    },
    //selectUser(item) {
    //  this.selectedUser != item.name
    //    ? (this.selectedUser = item.name)
    //    : (this.selectedUser = "");
    //},
    submit() {
      this.type == "sender"
        ? (this.letter.sender = this.selectedUser)
        : (this.letter.receiver = this.selectedUser);
      this.usersDialog = false;
    },
    goToWaitingList() {
      this.$router.push("/" + this.role + "/secretariat/waitingList");
    },
    goToFollowingList() {
      this.$router.push("/" + this.role + "/secretariat/reminders");
    },
    goToFirstContact() {
      this.currentPageContact = 1;
    },
    goToLastContact() {
      this.currentPageContact = Math.ceil(
        this.computedContacts.length / this.perPageContact
      );
    },
    goToFirstEmployee() {
      this.currentPageEmployee = 1;
    },
    goToLastEmployee() {
      this.currentPageEmployee = Math.ceil(
        this.computedUsers.length / this.perPageEmployee
      );
    },
    goToFirstSignatories() {
      this.currentPageSignatories = 1;
    },
    goToLastSignatories() {
      this.currentPageSignatories = Math.ceil(
        this.computedSignatories.length / this.perPageSignatories
      );
    },
    getEmployees() {
      this.employeesLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/internals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.employees = res.data;
          this.employeesLoading = false;
          this.selectEmployees = res.data.map((x) => {
            return {
              id: x.id,
              text: x.name,
            };
          });
          localStorage.setItem("employees", JSON.stringify(this.employees));
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    getContacts() {
      this.contactsLoading = true;

      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/externals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.contacts = res.data.users;
          localStorage.setItem("contacts", JSON.stringify(this.contacts));

          setTimeout(() => {
            this.contactsLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    getSignatories() {
      this.signatoriesLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/signatories",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.signatories = res.data;
          this.signatoriesLoading = false;
          localStorage.setItem("signatories", JSON.stringify(this.signatories));
        })
        .catch((err) => {
          this.signatoriesLoading = false;
          this.toast(err.response.data, "error");
        });
    },
    openSelectUserDialog(type) {
      if (type == "sender") {
        if (
          this.letterDetails.type == "internal" &&
          !localStorage.getItem("employees")
        ) {
          this.getEmployees();
        } else if (
          this.letterDetails.type == "outbox" &&
          !localStorage.getItem("signatories")
        ) {
          this.getSignatories();
        } else if (this.letterDetails.type == "inbox") {
          if (!localStorage.getItem("contacts")) {
            this.getContacts();
          }
        }
      } else {
        if (
          (this.letterDetails.type == "internal" ||
            this.letterDetails.type == "inbox") &&
          !localStorage.getItem("employees")
        ) {
          this.getEmployees();
        } else if (this.letterDetails.type == "outbox") {
          if (!localStorage.getItem("contacts")) {
            this.getContacts();
          }
        }
      }

      this.userType = type;
      this.choosePersonDialog = true;
      this.currentPageContact = 1;
      this.currentPageEmployee = 1;
      this.search = "";
    },
    selectUser(item) {
      this.userType == "sender"
        ? (this.selectedSender = item)
        : (this.selectedReceiver = item);
    },
    selectUsers(item) {
      this.selectedReceivers.push(item);
      this.selectedReceiverIds.push(item.id);
    },
    removeUser(item) {
      let index = this.selectedReceivers.findIndex((x) => {
        return item.id == x.id;
      });
      if (index > -1) {
        this.selectedReceivers.splice(index, 1);
        this.selectedReceiverIds.splice(index, 1);
      }
      if (this.selectedReceivers == 0) {
        this.letterDetails.selectedReceivers = "";
      }
    },
    submitUser() {
      if (
        this.letterDetails.type == "internal" &&
        this.userType == "receiver"
      ) {
        this.letterDetails.selectedReceivers = JSON.parse(
          JSON.stringify(this.selectedReceivers)
        );
        this.letterDetails.selectedReceiverIds = JSON.parse(
          JSON.stringify(this.selectedReceiverIds)
        );
        this.choosePersonDialog = false;
      } else {
        this.userType == "sender"
          ? (this.letterDetails.sender = this.selectedSender)
          : (this.letterDetails.receiver = this.selectedReceiver);
        this.choosePersonDialog = false;
      }
    },
    showDashboard() {
      this.isBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/dashboard/secretariatDashboard",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.dashboard = res.data;
            localStorage.setItem("canSign", res.data.canSign);
            setTimeout(() => {
              this.isLoaded = true;
            }, 300);
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    sendLetter() {
      this.vLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/draft",
        {
          title: this.letterDetails.title,
          date: this.letterDetails.date,
          type: this.letterDetails.type,
          senderId: this.letterDetails.sender.id,
          receiverId:
            this.letterDetails.type == "internal"
              ? this.letterDetails.selectedReceiverIds
              : this.letterDetails.receiver.id,
          tags: this.letterDetails.tags,
          text:
            this.letterDetails.type == "internal" ||
            this.letterDetails.type == "outbox"
              ? this.letterDetails.text
              : "",
          abstract:
            this.letterDetails.type == "inbox"
              ? this.letterDetails.abstract
              : "",
          status: "draft",
          isFast: true,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.toast("پیش‌نویس با موفقیت ثبت شد.", "success");
          this.vLoading = false;
          this.newLetterId = res.data;
          this.letterDetails = {
            title: "",
            date: "",
            type: "internal",
            sender: "",
            receiver: "",
            text: "",
            abstract: "",
            selectedReceivers: [],
            selectedReceiverIds: [],
          };
          this.selectedSender = {};
          this.selectedReceiver = {};
          this.selectedReceivers = [];
          this.selectedReceiverIds = [];
        })
        .catch((err) => {
          console.log(err);
          this.toast(err.response.data, "error");
          this.vLoading = false;
        });
    },
    goToLetterInfo() {
      this.vLoading = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/draft",
        {
          title: this.letterDetails.title,
          date: this.letterDetails.date,
          type: this.letterDetails.type,
          senderId: this.letterDetails.sender.id,
          receiverId:
            this.letterDetails.type == "internal"
              ? this.letterDetails.selectedReceiverIds
              : this.letterDetails.receiver.id,
          tags: this.letterDetails.tags,
          text:
            this.letterDetails.type == "internal" ||
            this.letterDetails.type == "outbox"
              ? this.letterDetails.text
              : "",
          abstract:
            this.letterDetails.type == "inbox"
              ? this.letterDetails.abstract
              : "",
          status: "draft",
          isFast: true,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.vLoading = false;
          this.newLetterId = res.data;

          if (this.letterDetails.type == "inbox") {
            this.letterType = "inbox";
          } else if (this.letterDetails.type == "outbox") {
            this.letterType = "outbox";
          } else if (this.letterDetails.type == "internal") {
            this.letterType = "internal";
          }

          this.$router.push(
            "/" +
              this.role +
              "/secretariat/drafts/completeDraft/" +
              this.newLetterId
          );
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.vLoading = false;
        });
    },
  },
  computed: {
    computedUsers() {
      let filtered;
      filtered = this.employees.filter((x) => {
        return x.name.includes(this.search);
      });
      this.currentPageEmployee = 1;
      return filtered;
    },
    computedSignatories() {
      let filtered;
      filtered = this.signatories.filter((x) => {
        return x.name.includes(this.search);
      });
      this.currentPageSignatories = 1;
      return filtered;
    },
    computedContacts() {
      let filtered;
      filtered = this.contacts.filter((x) =>
        x.choiceName.includes(this.search)
      );
      this.currentPageContact = 1;
      return filtered;
    },
    computedIsContact() {
      let isContact;
      if (this.letterDetails.type == "inbox" && this.userType == "sender") {
        isContact = true;
      } else if (
        this.letterDetails.type == "outbox" &&
        this.userType == "receiver"
      ) {
        isContact = true;
      } else {
        isContact = false;
      }
      return isContact;
    },
    computedIsSignatories() {
      let isSignatories;
      if (this.letterDetails.type == "outbox" && this.userType == "sender") {
        isSignatories = true;
      }
      return isSignatories;
    },
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("employees");
    localStorage.removeItem("contacts");
    localStorage.removeItem("signatories");
    next();
  },
  mounted() {
    this.role = localStorage.getItem("role");
    if (localStorage.getItem("employees")) {
      this.employees = JSON.parse(localStorage.getItem("employees"));
    }
    if (localStorage.getItem("contacts")) {
      this.contacts = JSON.parse(localStorage.getItem("contacts"));
    }
    if (localStorage.getItem("signatories")) {
      this.signatories = JSON.parse(localStorage.getItem("signatories"));
    }
    this.showDashboard();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.secretariat-dashboard {
  * {
    color: #5f6581;
    font-size: 14px;
  }

  .v-text-field .v-input__control,
  .v-text-field fieldset {
    border-radius: 8px !important;
  }
  .header {
    .row {
      background-color: #fff;
      border-radius: 30px;
      padding: 15px;
      .col {
        min-width: 200px;
        .item {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          .icon-box {
            width: 50px;
            height: 50px;
            min-width: 50px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .text-box {
            padding: 2px 15px;
            font-size: 14px;
            span {
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
        &:nth-child(1) {
          .icon-box {
            background-color: #7e57c2;
            .v-icon {
              transform: translateY(-2px);
            }
          }
        }
        &:nth-child(2) {
          .icon-box {
            background-color: #00acc1;
            .v-icon {
              transform: translateY(-2px);
            }
          }
        }
        &:nth-child(3) {
          .icon-box {
            background-color: #f5c2a4;
            .v-icon {
              transform: translateY(-2px);
              path {
                stroke: #fff;
              }
            }
          }
        }
        &:nth-child(4) {
          .icon-box {
            background-color: #f1a2a1;
            .v-icon {
              transform: translateY(-2px);
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
  .main-container {
    .see-more {
      span,
      i {
        color: #4c81c9;
      }
    }
  }
}
.dash-modal-card {
  padding: 20px !important;
  overflow: hidden !important;
  border-radius: 12px !important;

  .image-box {
    width: 65px;
    height: 65px;
    .v-image {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  .users {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    border-radius: 4px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #eee;
      opacity: 0.5;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bdbdbd;
      width: 10px;
      border-radius: 10px;
    }

    .selectedBox {
      background-color: #4c80c923;
    }
  }
  .blue-input {
    input {
      padding-right: 10px !important;
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-text-field .v-input__control,
    .v-text-field fieldset {
      border-radius: 12px !important;
      border-color: #4c81c9 !important;
    }
    .v-label {
      color: #4c81c9 !important;
    }
  }
  .blue-select {
    background-color: #e7f2fe;
    span {
      color: #4c81c9;
    }
    border-radius: 12px;
  }
  .blue-hover:hover {
    background-color: #e7f2fe;
    span {
      color: #4c81c9;
    }
    border-radius: 12px;
  }
}
.tag-box {
  position: relative;
  border: 1px solid #787878;
  border-radius: 10px;
  padding: 20px 10px;
  h5 {
    position: absolute;
    top: -12px;
    right: 20px;
    padding: 0 7px;
    background-color: #fff;
  }
}
.user-edit path {
  stroke: #4c81c9;
}
</style>
